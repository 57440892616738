import React from 'react'
const Logo = props => (
  <svg
    version="1.1"
    id="Ebene_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 153.8 35.9"
    style={{ enableBackground: 'new 0 0 153.8 35.9' }}
  >
    <g>
      <path
        className="st0"
        d="M42.2,13.9c0,0.1-0.1,0.2-0.2,0.2h-1.5c-0.1,0-0.2-0.1-0.2-0.2v-0.5c0-0.4-0.2-0.5-0.5-0.5H39
        c-0.3,0-0.5,0.2-0.5,0.5v1c0,0.2,0.2,0.4,0.7,0.7c0.4,0.2,1,0.5,1.8,1c0.8,0.4,1.2,0.9,1.2,1.6v2.7c0,0.5-0.2,1-0.6,1.3
        c-0.4,0.4-0.8,0.6-1.3,0.6h-1.9c-0.5,0-0.9-0.2-1.3-0.6c-0.4-0.4-0.5-0.8-0.5-1.3v-1c0-0.1,0.1-0.2,0.2-0.2h1.5
        c0.1,0,0.2,0.1,0.2,0.2v0.5c0,0.4,0.2,0.5,0.5,0.5h0.8c0.3,0,0.5-0.2,0.5-0.5v-1.5c0-0.2-0.2-0.4-0.7-0.7c-0.4-0.2-1-0.5-1.8-1
        c-0.8-0.4-1.2-0.9-1.2-1.6v-2.2c0-0.5,0.2-0.9,0.6-1.3c0.4-0.4,0.8-0.5,1.3-0.5h1.9c0.5,0,0.9,0.2,1.3,0.6c0.4,0.4,0.5,0.8,0.5,1.3
        V13.9z"
      />
      <path
        className="st0"
        d="M53,22c0,0.1,0,0.2-0.2,0.2h-1.7c-0.1,0-0.2,0-0.2-0.2l-0.2-1.9H49L48.8,22c0,0.1-0.1,0.2-0.2,0.2h-1.7
        c-0.1,0-0.2-0.1-0.2-0.2c0.1-0.7,0.1-1.4,0.2-2.1c0.1-0.8,0.2-1.5,0.3-2c0.5-2,1-4.3,1.7-6.8c0-0.1,0.1-0.1,0.2-0.1h1.6
        c0.1,0,0.1,0,0.2,0.1c0.1,0.6,0.7,2.8,1.6,6.7c0.1,0.5,0.2,1.2,0.3,2.1C52.9,21.2,53,21.9,53,22z M50.8,18.2L49.9,14L49,18.2H50.8z
        "
      />
      <path
        className="st0"
        d="M62.5,12.7c0,0.1-0.1,0.2-0.2,0.2h-3.6V16h2.7c0.1,0,0.2,0.1,0.2,0.2v1.5c0,0.1-0.1,0.2-0.2,0.2h-2.7V22
        c0,0.1-0.1,0.2-0.2,0.2h-1.5c-0.1,0-0.2-0.1-0.2-0.2V11.1c0-0.1,0.1-0.2,0.2-0.2h5.3c0.1,0,0.2,0.1,0.2,0.2V12.7z"
      />
      <path
        className="st0"
        d="M72.6,22c0,0.1-0.1,0.2-0.2,0.2h-5.3c-0.1,0-0.2-0.1-0.2-0.2V11.1c0-0.1,0.1-0.2,0.2-0.2h5.3
        c0.1,0,0.2,0.1,0.2,0.2v1.5c0,0.1-0.1,0.2-0.2,0.2h-3.6v2.3h2.7c0.1,0,0.2,0.1,0.2,0.2v1.5c0,0.1-0.1,0.2-0.2,0.2h-2.7v3.2h3.6
        c0.1,0,0.2,0.1,0.2,0.2V22z"
      />
      <path
        className="st0"
        d="M82.8,12.7c0,0.1-0.1,0.2-0.2,0.2h-1.7V22c0,0.1-0.1,0.2-0.2,0.2h-1.5c-0.1,0-0.2-0.1-0.2-0.2v-9.1h-1.7
        c-0.1,0-0.2-0.1-0.2-0.2v-1.5c0-0.1,0.1-0.2,0.2-0.2h5.3c0.1,0,0.2,0.1,0.2,0.2V12.7z"
      />
      <path
        className="st0"
        d="M93.6,11.1c-0.1,0.5-0.2,1.4-0.5,2.8c-0.3,1.1-0.8,2.9-1.7,5.3V22c0,0.1-0.1,0.2-0.2,0.2h-1.5
        c-0.1,0-0.2-0.1-0.2-0.2v-2.7c-0.7-1.9-1.2-3.7-1.7-5.4c-0.2-0.6-0.3-1.5-0.5-2.7c0-0.1,0.1-0.2,0.2-0.2h1.7c0.1,0,0.2,0.1,0.2,0.2
        l0.2,1.6l0.9,3.5l0.9-3.5l0.2-1.6c0-0.1,0.1-0.2,0.2-0.2h1.7C93.5,11,93.6,11,93.6,11.1"
      />
      <path
        className="st0"
        d="M103.1,22c0,0.1-0.1,0.2-0.2,0.2h-3.6c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.6-0.8-0.6-1.4v-7.5
        c0-0.5,0.2-0.9,0.6-1.3c0.4-0.4,0.8-0.5,1.3-0.5h3.6c0.1,0,0.2,0.1,0.2,0.2v1.5c0,0.1-0.1,0.2-0.2,0.2h-3c-0.3,0-0.5,0.2-0.5,0.5
        v6.4c0,0.3,0.2,0.5,0.7,0.5h2.9c0.1,0,0.2,0.1,0.2,0.2V22z"
      />
      <path
        className="st0"
        d="M113.2,22c0,0.1-0.1,0.2-0.2,0.2h-1.5c-0.1,0-0.2-0.1-0.2-0.2v-4.6h-1.8V22c0,0.1-0.1,0.2-0.2,0.2h-1.5
        c-0.1,0-0.2-0.1-0.2-0.2V11.1c0-0.1,0.1-0.2,0.2-0.2h1.5c0.1,0,0.2,0.1,0.2,0.2v4.4h1.8v-4.4c0-0.1,0.1-0.2,0.2-0.2h1.5
        c0.1,0,0.2,0.1,0.2,0.2V22z"
      />
      <path
        className="st0"
        d="M123.4,22c0,0.1-0.1,0.2-0.2,0.2h-5.3c-0.1,0-0.2-0.1-0.2-0.2V11.1c0-0.1,0.1-0.2,0.2-0.2h5.3
        c0.1,0,0.2,0.1,0.2,0.2v1.5c0,0.1-0.1,0.2-0.2,0.2h-3.6v2.3h2.7c0.1,0,0.2,0.1,0.2,0.2v1.5c0,0.1-0.1,0.2-0.2,0.2h-2.7v3.2h3.6
        c0.1,0,0.2,0.1,0.2,0.2V22z"
      />
      <path
        className="st0"
        d="M133.5,22c0,0.1-0.1,0.2-0.2,0.2h-3.6c-0.5,0-0.9-0.2-1.3-0.5c-0.4-0.3-0.6-0.8-0.6-1.4v-7.5
        c0-0.5,0.2-0.9,0.6-1.3c0.4-0.4,0.8-0.5,1.3-0.5h3.6c0.1,0,0.2,0.1,0.2,0.2v1.5c0,0.1-0.1,0.2-0.2,0.2h-3c-0.3,0-0.5,0.2-0.5,0.5
        v6.4c0,0.3,0.2,0.5,0.7,0.5h2.9c0.1,0,0.2,0.1,0.2,0.2V22z"
      />
      <path
        className="st0"
        d="M144.1,21.9c0.1,0.2,0,0.2-0.2,0.2h-1.7c-0.1,0-0.1,0-0.2-0.1l-2.2-4.8V22c0,0.1-0.1,0.2-0.2,0.2h-1.5
        c-0.1,0-0.2-0.1-0.2-0.2V11.1c0-0.1,0.1-0.2,0.2-0.2h1.5c0.1,0,0.2,0.1,0.2,0.2v4.7l2-4.8c0-0.1,0.1-0.1,0.2-0.1h1.7
        c0.2,0,0.2,0.1,0.2,0.2l-2.1,5.4L144.1,21.9z"
      />
      <path
        className="st0"
        d="M153.8,13.9c0,0.1-0.1,0.2-0.2,0.2h-1.5c-0.1,0-0.2-0.1-0.2-0.2v-0.5c0-0.4-0.2-0.5-0.5-0.5h-0.8
        c-0.3,0-0.5,0.2-0.5,0.5v1c0,0.2,0.2,0.4,0.7,0.7c0.4,0.2,1,0.5,1.8,1c0.8,0.4,1.2,0.9,1.2,1.6v2.7c0,0.5-0.2,1-0.6,1.3
        c-0.4,0.4-0.8,0.6-1.3,0.6h-1.9c-0.5,0-0.9-0.2-1.3-0.6c-0.4-0.4-0.5-0.8-0.5-1.3v-1c0-0.1,0.1-0.2,0.2-0.2h1.5
        c0.1,0,0.2,0.1,0.2,0.2v0.5c0,0.4,0.2,0.5,0.5,0.5h0.8c0.3,0,0.5-0.2,0.5-0.5v-1.5c0-0.2-0.2-0.4-0.7-0.7c-0.4-0.2-1-0.5-1.8-1
        s-1.2-0.9-1.2-1.6v-2.2c0-0.5,0.2-0.9,0.6-1.3c0.4-0.4,0.8-0.5,1.3-0.5h1.9c0.5,0,0.9,0.2,1.3,0.6s0.5,0.8,0.5,1.3V13.9z"
      />
      <path
        className="st1"
        d="M7.1,0L0,7.1v15.6c0,6.3,4.2,8.2,11.9,13c0.6,0.4,1.4,0.4,2,0c7.7-4.8,11.9-6.6,11.9-13V1.2
        c0-0.7-0.6-1.2-1.2-1.2H7.1z"
      />
      <path
        className="st2"
        d="M6.6,17.8l3.3,4.8c0,0.1,0.1,0.1,0.2,0l10.7-9.4c0.1-0.1,0-0.3-0.1-0.2l-10.6,6l-3.2-1.4
        C6.6,17.6,6.5,17.7,6.6,17.8"
      />
    </g>
    <style jsx>{`
      .st0 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: ${props.color ? props.color : '#404944'};
      }
      .st1 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: ${props.color ? props.color : '#41A281'}};
      }
      .st2 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: ${props.color ? '#41A281' : '#FFFFFF'};
      }
    `}</style>
  </svg>
)

export default Logo
